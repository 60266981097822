import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Layout from "../../components/layout";

class SlidesIndex extends React.Component {
  render() {

    return (
  <Layout location={this.props.location}>
    <div>
        <Helmet title="Slides / Presentations by Paul Querna" />

        <div className="page-header">
          <h1>Slides / Presentations by Paul Querna</h1>
        </div>

<h2>2017</h2>
<ul>
<li>June 6, 2017: - OpenID Connect all the Things @ CoreOS Fest 2017 [<a href="https://www.youtube.com/watch?v=8bgRQObwgxQ">VIDEO</a>] [<a href="/slides/OIDC-CoreOS-Fest-2017.pdf">PDF</a>]</li>
</ul>

<h2>2016</h2>
<ul>
<li>May 6, 2016: - Navigating Compliance in a CoreOS World
 @ CoreOS Fest 2016, Berlin, Germany. [<a href="/slides/compliance-on-coreos.pdf">PDF</a>]</li>
</ul>

<h2>2013</h2>
<ul>
<li>May 6, 2013: - Choices @ <a href="https://www.meetup.com/Frontier-Real-time-Streaming-Big-Data-Virtualization/events/111780452/">Migrating from Data Center to Cloud: Public, Private, Hybrid, IaaS, PaaS, SaaS</a>   [<a href="/slides/migrating-from-datacenters.pdf">PDF</a>]</li>
</ul>

<h2>2012</h2>
<ul>
<li>June 27, 2012: - Logging as Event Streams @ Velocity [<a href="/slides/logs-as-event-streams/">HTML</a>]</li>
<li>May 4, 2012: - Code Reviews @ Rackspace SFO Hackday  [<a href="code-reviews/">HTML</a>]</li>
<li>February 29, 2012: - API Servers in Node.js @ Rackspace SFO Node.js Hackday  [<a href="/slides/api-servers-in-nodejs/">HTML</a>]</li>
</ul>

<h2>2011</h2>
<ul>
<li>July 26, 2011: - Node.js at Cloudkick @ OSCON Node Day 2011 [<a href="/slides/node-at-rax-oscon2011.pdf">PDF</a>]</li>
<li>June 12, 2011: - Whiskey Test Framework @ NodeCamp EU 2011 [<a href="/slides/whiskey-nodecampeu.pdf">PDF</a>]</li>
<li>May 5, 2011: - Cast & Node.js @ NodeConf 2011 [<a href="cast-nodeconf2011.pdf">PDF</a>]</li>
</ul>

<h2>2010</h2>

<ul>
<li>December 14, 2010: - UDP in Node.js @ Node.js Camp 2010 [<a href="/slides/nodejs-udp-nodecamp2010.pdf">PDF</a>]</li>
<li>September 30, 2010: - Apache Libcloud: API driven operations @ Surge 2010 [<a href="/slides/libcloud-surge2010.pdf ">PDF</a>]</li>
<li>August 11, 2010: - Cassandra At Cloudkick @ Cassandra Summit 2010 [<a href="/slides/cassandra-summit-cloudkick.pdf">PDF</a>]</li>
<li>June 22, 2010: - Apache Libcloud @ Velocity Ignite [<a href="/slides/libcloud-ignite.pdf">PDF</a>]</li>
<li>June 1, 2010: - Apache Libcloud @ <a href="http://opensourcebridge.org/">Open Source Bridge</a> [<a href="/slides/libcloud-2010-06.pdf">PDF</a>]</li>
</ul>

<h2>2007</h2>
<ul>
<li>October 2, 2007: geekSessions 1.2: Designing Beyond the Database [<a href="/slides/bl-geeksessions.pdf">PDF</a>]</li>
</ul>

<h2>2006</h2>
<ul>
<li>August 10, 2006: Expert Panel on Blog Spam @ <a href="http://airweb.cse.lehigh.edu/2006/">AIRWeb 2006</a> [<a href="/slides/blog_spam.pdf">PDF</a>]</li>
</ul>

<h2>2005</h2>
<ul>
<li>December 10, 2005: MO01: What's new in httpd 2.2 @ ApacheCon US 2005 San Diego [<a href="/slides/new_in_httpd_2_2.pdf">PDF</a>]</li>
<li>July 18, 2005: DE1225: What's new in httpd 2.2 @ ApacheCon EU 2005 Stuttgart. [<a href="/slides/new_in_httpd_2_2.pdf">PDF</a>]</li>
<li>July 18, 2005: DE1312: Developing Applications with APR @ ApacheCon EU 2005 Stuttgart <a href="/slides/dev_apr.pdf">[PDF</a>]</li>
</ul>

<h2>2004</h2>
<ul>
<li>November 13, 2004: TU19: Using XSL and mod_transform in Apache Applications @ ApacheCon US 2004 Las Vegas. [<a href="/slides/tu19_mod_transform_slides.pdf">PDF</a>]</li>
<li>November 13, 2004: WE20: Mass Virtual Hosting with Apache 2.0 @ ApacheCon US 2004 Las Vegas [<a href="/slides/we20_mass_virtual_hosting_slides.pdf">PDF</a>]</li>
</ul>
      </div>
  </Layout>
    )
  }
}

SlidesIndex.propTypes = {
  route: PropTypes.object,
}

export default SlidesIndex
